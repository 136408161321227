/* 🔥 */
* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;

  /* Bugfix for blue border when tab on modile and tablet devices */
  /* -webkit-tap-highlight-color: rgba(255, 255, 255, 0); */
  -webkit-tap-highlight-color: transparent;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

::selection {
  /* fix for hover highlight text */
  background: var(--selection-bg);
  -webkit-text-fill-color: var(--black);
  background-color: var(--selection-bg);
  color: var(--black);
}

/* * applies a black outline to element only when focused via keyboard */
:focus-visible:not([data-focusvisible-polyfill]){
  outline: var(--text-highlight) auto 1px;
}

/* when the element receives focus that is not keyboard focus */
:focus:not(:focus-visible):not([data-focusvisible-polyfill]){
  outline: none;
}

/* fix CSS3 gradient background set !90deg on body doesn't stretch but instead repeats? */

/* https://stackoverflow.com/a/3294418/6294371 */

/* html {
  min-height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;

  background: var(--color-global-bg-fallback);
  background-image: var(--color-global-bg);
} */

/* fix end */
body::before,
body::after {
  content: '';
  display: block;
  background-attachment: scroll;
  position: fixed;
  top: 0;
  left: 0;

  /* width: 100%;
  height: 100%; */

  width: 100vw;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  will-change: transform;
  transform: scale(1);
  transition: opacity 1s linear;
}

body::before {
  background: var(--color-global-bg-fallback-light);
  background-image: var(--color-global-bg-light);
}

body::after {
  background: var(--color-global-bg-fallback-dark);
  background-image: var(--color-global-bg-dark);
  opacity: 0;
}

body[data-theme='dark']::after {
  opacity: 1;
}

body[data-theme='gray']::after {
  opacity: 1;
  background-image: var(--color-global-bg-gray);
  filter: grayscale(100%);
  /* stylelint-disable */
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  /* stylelint-enable */
}

body[data-theme='gray'] * {
  filter: grayscale(100%);
  /* stylelint-disable */
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  /* stylelint-enable */
}

body {
  color: var(--text-color);
  animation: fade-in 3s ease;
  will-change: opacity;

  /* overflow-x: hidden; */
  /* display: flex;
  flex-direction: column;
  height: 100vh; */
  display: grid;
  grid-gap: 3px;
  grid-template-rows: auto 1fr auto;
}

.app-header {
  grid-column: 1 / -1;
}

.app-main {
  grid-column: 1 / -1;
  display: grid !important;
  grid-gap: 3px;
  grid-template-columns: minmax(150px, 20%) 1fr minmax(150px, 20%);
}

.app-footer {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line */
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.app-footer .version {
  /* margin-left: auto; */
}

.app-footer .online-days {
  font-size: 0.5rem;
  padding-top: 0.5em;
}

.left-side {
  grid-column: span 1;

  /* width: clamp(100px, 50px, 200px); */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-content {
  grid-column: span 1;
  padding-left: 10px;
  padding-right: 10px;
}

.right-side {
  grid-column: span 1;
}

.full-bleed {
  grid-column: 1 / -1;
}

@media screen and (max-width: 672px) {
  .app-main {
    grid-template-rows: 1fr auto auto;
  }
  .left-side {
    grid-row: 2;
    grid-column: 1 / -1;
  }
  .app-content {
    grid-column: 1 / -1;
  }
  .right-side {
    grid-column: 1 / -1;
  }
  .globe-container {
    margin-left: auto;
    margin-bottom: auto;
  }
}

.globe-container {
  margin-left: 1em;
  margin-bottom: 3em;
}

.help-icon-menu-bar {
  padding-top: 10px;
  margin-right: 1rem;
  text-align: right;
}

.help-icon-menu-bar .help-icon {
  background: transparent;
  height: 28px;
  width: 26px;
  border: none;
  cursor: pointer;
  opacity: 0.54;
  transition: opacity 0.333s cubic-bezier(0, 0, 0.21, 1);
}

.help-icon-menu-bar .help-icon:hover {
  opacity: 1;
}

@media (prefers-reduced-motion) {
  .bird-fly {
    display: none
  }
}

.bird-big-left,
.bird-big-right,
.bird-small-left,
.bird-small-right {
  position: absolute;

  /* open hardware acceleration */
  transform: translateZ(0);
}

.bird-big-left {
  opacity: 1;
  top: 50px;
  left: 0;
  width: 10px;
  height: 10px;
  border: 2px solid var(--black);
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: rotateZ(-75deg);
  animation: move var(--bird-fly-speed) linear infinite, flap 0.5s linear alternate infinite;
  z-index: 70;
}

.bird-big-right {
  z-index: 70;
  opacity: 1;
  top: 50px;
  width: 10px;
  height: 10px;
  border: 2px solid var(--black);
  border-radius: 50%;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  transform: rotateZ(80deg);
  margin: 0 6px;
  animation: move var(--bird-fly-speed) linear infinite, flap 0.5s linear alternate infinite;
}

.bird-small-left {
  opacity: 1;
  top: 45px;
  left: 0;
  width: 10px;
  height: 8px;
  border: 2px solid var(--black);
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: rotateZ(-80deg);
  animation: move2 var(--bird-fly-speed) linear infinite 1.5s,
    flap 0.5s linear alternate infinite 1.5s;
  z-index: 70;
}

.bird-small-right {
  z-index: 70;
  opacity: 1;
  top: 45px;
  left: 0;
  width: 10px;
  height: 8px;
  border: 2px solid var(--black);
  border-radius: 50%;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  transform: rotateZ(75deg);
  margin: 0 5px;
  animation: move2 var(--bird-fly-speed) linear infinite 1.5s,
    flap 0.5s linear alternate infinite 1.5s;
}

[class^='moon'] {
  position: absolute;
  top: 7%;
  bottom: 0;
  left: 60%;
  right: 0;
  margin: 0 auto;
  max-height: 10vw;
  max-width: 10vw;
  cursor: pointer;
  border-radius: 50%;
  background: var(--color-moon-bg);
  background-size: var(--color-moon-bg-size);

  /* open hardware acceleration */
  transform: translateZ(0);
  animation: colorize 24s 0s cubic-bezier(0.2, 1.05, 0.45, 0.94) infinite alternate, rise 5s forwards;
}

@media screen and (max-height: 500px) {
  [class^='moon'] {
    top: 14%;
  }
}

.satellite {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1vw;
  height: 1vw;
  font-size: var(--satellite-size);

  /* background: var(--satellite-color); */
  border-radius: 50%;
  will-change: transform;
  animation: orbit 60s linear infinite;
}

@media (prefers-reduced-motion) {
  .satellite {
    animation: none;
    display: none
  }
}

.centered-content {
  margin: auto;
  margin-top: 5%;
  line-height: 1.5;
}

.brand-icon {
  width: calc(5em + 4vw) !important;
  height: calc(5em + 4vw) !important;
  position: relative;
  transform-origin: bottom;
  color: var(--color-brand);
  text-shadow: var(--brand-text-shadow);
  transition: transform ease-in-out 3s;
}

.brand-icon:hover {
  /* animation: jump infinite 5s cubic-bezier(0.28, 0.84, 0.42, 1); */
  animation: jello-horizontal 0.9s both;
}

@media (prefers-reduced-motion) {
  .brand-icon:hover {
    animation-name: none
  }
}

/* .brand-icon:hover.flip-vertical-right {
  animation: flip-vertical-right infinite 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
} */

.bio {
  position: relative;
  text-align: justify;
  font-weight: 900;
  font-size: calc(1em + 1vw);

  /* font-size: clamp(min, viewport-width-unit, max); */
  /* letter-spacing: -0.1vw; */
  padding: 1rem;
  transition: 1s;
}

.bio .code-icon {
  position: absolute;
  right: 10px;
  top: -1rem;
}

.wechat-qrcode-container {
  position: relative;
}

.wechat-qrcode {
  width: calc(2em + 10vw);
  height: auto;

  /* aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: contain; */
  cursor: pointer;
  will-change: transform;
  transition: transform 1.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.wechat-qrcode-desc {
  color: var(--text-subtle-color);
  font-style: italic;
  font-weight: bold;
  width: 10rem;

  /* margin-top: -10px; */
  font-size: 7px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 0;
}

@media only screen and (max-width: 767px), only screen and (device-width: 767px) {
  .wechat-qrcode-desc {
    font-size: 6px;

    /* margin-top: -5px;
    line-height: 7px; */
    width: 5rem;
    bottom: 0;
  }
}

/* -- icons style -- */
.social-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 426px), only screen and (device-width: 426px) {
  .social-icons {
    flex-wrap: wrap-reverse;

    /* justify-content: space-evenly; */
  }
}

.social-icons li {
  display: inline-block;
}

.social-icons li a {
  text-decoration: none;
  will-change: transform;
  transition: transform 0.3s ease-in-out 0s;
}

.social-icons li a:hover {
  transform: translateY(-3px);
}

.icon {
  margin: calc(0.5em + 0.1vw);
  margin-bottom: 2px;
  opacity: 0.8;
  color: var(--text-color);
  display: flex;
  align-items: center;

  /* background: linear-gradient(0deg, var(--bg-light), var(--bg-white)); */
  /* transition: 1s; */
  /* transition: all 0.3s ease 0s; */
}

.icon.circle {
  height: calc(2em + 0.5vw);
  width: calc(2em + 0.5vw);
}

.icon .svg {
  margin: 0 auto;
  width: 1.5rem;
  height: 1.5rem;
}

.icon.loading {
  /* background: var(--bg-light); */
  /* border-radius: 4px; */
  /* display: block; */
  position: relative;
  overflow: hidden;
}

.icon.loading::before {
  content: '';
  animation: sweep-loading 1.3s infinite;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  /* transform: translate(-50%, -50%); */
  z-index: 1;
  background: linear-gradient(135deg, transparent 30%, var(--bg-gray) 50%, transparent 70%);
}

.icon-facebook {
  background: var(--fb);
}

.icon-twitter {
  background: var(--twitter);
}

.icon-google {
  background: var(--google);
}

.icon-linkedin {
  background: var(--linkedin);
}

.icon-snapchat-ghost {
  background: var(--snapchat);

  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.icon-skype {
  background: var(--skype);
}

.icon-wechat {
  background: var(--wechat);
}

.icon-envelope-o {
  background: var(--envelope);
}

.icon-reddit {
  background: var(--reddit);
}

.icon-github {
  background: var(--github);
}

.icon-stackoverflow {
  background: var(--stackoverflow);
}

.icon-slack {
  background: var(--slack);
}

.icon-telegram {
  background: var(--telegram);
}

.developer-mode-link {
  opacity: 0.54;
}

.developer-mode-link:hover {
  opacity: 1;
}

/* -- my-portfolio style -- */
details.my-portfolio {
  border-radius: 24px;
  box-shadow: 0 0 4px var(--color-shadow2);
}

details.my-portfolio summary {
  padding: 4px;
  border: none;
  font-size: calc(1em + 1vw);
}

details.my-portfolio:not([open]) summary {
  border-radius: 24px;
}

details.my-portfolio[open] summary {
  box-shadow: 0 2px 3px 1px rgb(228 179 179 / 20%);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

details.my-portfolio[open] summary + div {
  animation: sweep 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* -- my-portfolio end -- */
.container-grid {
  display: grid;
  grid-gap: 0.2rem;
  grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
  grid-auto-rows: 70px;
}

.container-grid > .card {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  transition: opacity 0.3s ease 0s;
}

.container-grid > .card.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.container-grid > .card:active,
.container-grid > .card:focus,
.container-grid > .card:hover {
  background: var(--color-card-bg);
}

.container-grid > .card:not(.disabled):active .icon .svg,
.container-grid > .card:not(.disabled):focus .icon .svg,
.container-grid > .card:not(.disabled):hover .icon .svg {
  animation: heartbeat 1.17s ease-in-out infinite both;
}

.container-grid > .card > .link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.container-grid > .card.disabled > .link {
  pointer-events: none;
}

.qrcode-handler {
  margin-right: 1rem;
  cursor: pointer;
  opacity: 0.54;
  border: none;
  background: transparent;
}

.qrcode-handler:hover {
  opacity: 1;
}

.mobile-qrcode {
  width: 100% !important;
  aspect-ratio: 1 / 1;

  /* object-fit: contain; */
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 var(--color-shadow2);
  will-change: transform;
  transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;
}

.mobile-qrcode:hover {
  transform: translate3d(0, -5px, 0);
  /* stylelint-disable */
  box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 1%),
    6px 6px 6px 6px rgba(34, 33, 81, 15%);
  /* stylelint-enable */
}

.corner-bars {
  position: fixed;
  bottom: 1em;
  right: 0;
}

.signature {
  stroke-width: 0.5rem !important;
  height: 2rem !important;
  width: 7rem !important;
  margin-right: 2rem;
}

.svgicon,
[class^='svgicon-'],
[class*=' svgicon-'] {
  display: inline-block;
  width: 24px;
  height: 24px;
  stroke-width: 0;
  stroke: currentcolor;
  fill: currentcolor;
}

.no-scirpt-warning-msg {
  text-align: center;
  margin-bottom: 4rem;
  color: var(--yellow3);
  font-style: italic;
}

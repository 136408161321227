
/* Animations */
@keyframes fade-in {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes move {
  0% {
    left: 0;
  }
  33% {
    top: 20px;
  }
  50% {
    top: 70px;
  }
  66% {
    top: 100px;
  }
  100% {
    left: 90%;
  }
}

@keyframes move2 {
  from {
    left: 0;
  }
  to {
    left: 90%;
  }
}

@keyframes flap {
  from {
    border-radius: 90%;
  }
  to {
    border-radius: 10%;
  }
}

@keyframes colorize {
  0% {
    box-shadow: 0 0 40px 6px #f20, 0 0 150px 10px #f33;
  }
  35% {
    box-shadow: 0 0 40px 6px orange, 0 0 150px 10px #e0596b;
  }
  65% {
    box-shadow: none;
  }
  85% {
    box-shadow: none;
    background: linear-gradient(gold, #d90 #d00);
    background-position: 50% 0;
  }
  100% {
    box-shadow: 0 0 40px 6px #efb503, 0 0 150px 10px #d66c39;
  }
}

@keyframes rise {
  from {
    top: 40%;
    left: -50%;
    opacity: 0;
    background-position: 50% 100%;
    transform: scale(1.2);
  }
  to {
    top: 7%;
    left: 60%;
    opacity: 1;
    background-position: 50% 0;
    transform: scale(1);
  }
}

@keyframes orbit {
  from {
    transform:
      rotate(0deg)
      translateX(calc(200% + 5vw));
  }
  to {
    transform:
      rotate(360deg)
      translateX(calc(200% + 5vw));
  }
}

@keyframes jump {
  0% { transform: scale(1, 1)      translateY(0); }
  10% { transform: scale(1.1, 0.9)   translateY(0); }
  30% { transform: scale(0.9, 1.1)   translateY(-100px); }
  50% { transform: scale(1.05, 0.95) translateY(0); }
  57% { transform: scale(1, 1)      translateY(-7px); }
  64% { transform: scale(1, 1)      translateY(0); }
  100% { transform: scale(1, 1)      translateY(0); }
}

 @keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

 @keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}


@keyframes sweep {
  from {
    margin-left: -1.5em;
    margin-right: 1.5em;
    margin-top: -1.5em;
    opacity: 0;
  }
  to {
    /* margin-left: 0;
    margin-right: 0;
    margin-top: 0; */
    opacity: 1;
  }
}

@keyframes sweep-loading {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(50%, 50%);
  }
}

@keyframes flip-vertical-right {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes color-change {
  0% {
    filter: hue-rotate(0deg)
  }
  to {
    filter: hue-rotate(360deg)
  }
}

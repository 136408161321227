.text-nowrap {
  white-space: nowrap;
}

.font-weight-bold {
  font-weight: 900;
}

.hover-visible:hover,
.hover-visible:focus {
  opacity: 1;
  box-shadow: 0 2px 5px var(--subtle3);

  /* transition: transform 0.1s ease-out;
  transform: scale(1.25); */
}

.peach-gradient {
  background: var(--color-peach-gradient) !important;
}

.purple-gradient {
  background: var(--color-purple-gradient) !important;
}

.box-shadow {
  box-shadow: -10px 10px 15px var(--subtle3);
}

/* .border-active:not(.disabled):active {
  outline: var(--text-highlight) auto 1px;
} */

.line-h {
  background-color: lightgray;
  border: none;
  height: 1px;
}

.p-relative {
  position: relative;
}

.text-shadow {
  text-shadow: -10px 10px 15px rgb(0 0 0 / 30%);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-white {
  color: var(--white) !important;
}

.text-black {
  color: var(--black);
}

.mr-1 {
  margin-right: 1rem;
}

.icon-size-md {
  width: 2rem !important;
  height: 2rem !important;
}

.circle {
  border-radius: 100%;
}

.rounded {
  border-radius: 4px;
}

.scale {
  transform: scale(1.8) !important;
}

.highlight {
  color: var(--text-highlight);
  cursor: help;
  border-bottom-style: dotted;
  position: relative;
  transition: border .2s ease-in-out;
}

.highlight::before {
  font-size: 0.7em;
	content: "";
  position: absolute;
  width: 0;
  left: 0;
  bottom: -0.3em;
  transition: width .2s ease-in-out;

  /* height: 0.125em;
  background-color: rgb(255, 156, 52); */

  height: 0.2em;

  /* background-image: url("data:image/svg+xml,%3Csvg width='53' height='4' viewBox='0 0 53 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M51.9 3C49 3 49 1 46.2 1c-2.8 0-2.8 2-5.7 2-2.8 0-2.8-2-5.7-2C32 1 32 3 29.1 3c-2.8 0-2.8-2-5.7-2-2.8 0-2.8 2-5.6 2S15 1 12.1 1C9.5 1 9.5 3 6.7 3 3.8 3 3.8 1 1 1' stroke='rgb(255, 156, 52)' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='52.9' height='4' fill='%23404040'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E"); */
  background-image: url("./../assets/images/bases/wave.svg");
  background-repeat: repeat-x;
}

.highlight:focus::before,
.highlight:hover::before {
  width: 100%;
  right: 0;
  z-index: 1;
}

.highlight:focus,
.highlight:hover {
  border-bottom-style: none;
  color: var(--text-highlight2);
  outline: none !important;

  /* text-shadow: -3px 3px 2px #7c630a; */
  /* background: linear-gradient(330deg, #178bb7 0%, #d8ca1e 25%, #52e0e0 50%, #f1e526 75%, #095090 100%); */
  /* background: linear-gradient(330deg, #178bb7 0%, #8bc1ef 25%, #52e0e0 50%, #f1e526 75%, #076b39 100%); */
  background: linear-gradient(90deg, #e86ff1 0%, #3ce2e2 70%, #f5b45a 100%);

  /* background: rgb(63,94,251);
  background: radial-gradient(circle, #ffe506 0%, purple 100%); */
  /* github */
  /* background: linear-gradient(92.05deg, #BCA1F7 12.09%, #E577B4 42.58%, #FF7170 84.96%); */

  background-clip: text;

  /* stylelint-disable-next-line */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  animation: color-change 6s alternate infinite
}

[data-theme="dark"] .highlight:hover {
  background: linear-gradient(130deg, #507bf4, #f166b2);
  background-clip: text;

  /* stylelint-disable-next-line */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
}

mark::before {
  content: ' [highlight start] ';
}

mark::after {
  content: ' [highlight end] ';
}

/* Hide the text created in the CSS content property */
mark::before,
mark::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.scan-overlay {
  position: relative;
}

.scan-overlay::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url("./../assets/images/bases//glitch-overlay.svg");
  background-size: 100% 100%;
  opacity: .3;
  pointer-events: none;
}

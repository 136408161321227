:root {
  --white: #fff;
  --gray1: #f8f9fa;
  --gray2: #e9ecef;
  --gray3: #dee2e6;
  --gray4: #ced4da;
  --gray5: #adb5bd;
  --gray6: #6c757d;
  --gray7: #495057;
  --gray8: #343a40;
  --gray9: #212529;
  --black: #000;
  --black2: rgb(0 0 0 / 17%);
  --blue1: rgb(29 26 79);
  --blue2: #3b5998;
  --blue3: #00aced;
  --blue4: #178bb7;
  --purple1: rgb(128 13 206);
  --purple2: rgb(131 58 180 / 100%);
  --purple3: #833ab4;
  --pink1: #f166b2;
  --red1: #d00;
  --red2: #f20;
  --red3: #f33;
  --red4: rgb(236 61 61);
  --red5: rgb(209 63 63 / 100%);
  --orange1: #f1a50d;
  --orange2: rgb(220 144 37);
  --orange3: rgb(194 141 65 / 100%);
  --orange4: #ab870a;
  --orange5: #f5b45a;
  --orange6: #fc8b53;
  --orange7: #efbb35;
  --yellow1: #ffe506;
  --yellow2: #fdd55a;
  --yellow3: #d8ca1e;
  --green1: #076b39;
  --green3: #4aae9b;
  --cyan1: #3ce2e2;
  --light: var(--gray1);
  --dark: var(--gray8);
  --blue: var(--blue1);
  --purple: var(--purple1);
  --pink: var(--pink1);
  --red: var(--red1);
  --orange: var(--orange1);
  --yellow: var(--yellow1);
  --green: var(--green1);
  --cyan: var(--cyan1);
  --gray: var(--gray5);
  --subtle: rgb(0 0 0 / 10%);
  --subtle2: rgb(0 0 0 / 5%);
  --subtle3: rgb(0 0 0 / 30%);
  --text-highlight: var(--orange1);
  --text-highlight2: var(--yellow1);
  --text-color: var(--white);
  --text-subtle-color: var(--gray6);
  --selection-bg: var(--orange7);
  --color-shadow: var(--gray2);
  --color-shadow2: var(--gray4);
  --bg-white: var(--white);
  --bg-light: var(--gray3);
  --bg-gray: #fff;
  --color-global-bg-deg: 45deg;
  --color-global-bg-fallback-light: #833ab4;
  --color-global-bg-fallback-dark: rgb(29 26 79);
  --color-global-bg-light: linear-gradient(var(--color-global-bg-deg), rgb(28 17 115) 0%, rgb(238 36 36) 70%, rgb(220 144 37) 100%);
  --color-global-bg-dark: linear-gradient(var(--color-global-bg-deg), rgb(1 20 86) 0%, rgb(107 3 109) 100%);

  /* --color-global-bg-dark: linear-gradient(var(--color-global-bg-deg), rgba(19,0,45,1) 0%, rgba(19,0,45,1) 0%, rgba(92,16,160,1) 100%); */
  --color-global-bg-gray: gray;
  --color-card-bg: radial-gradient(circle farthest-corner at right bottom, #9e1985 0, #d08452 100%);

  /* --color-moon-bg: linear-gradient(gold, #d00); */
  --color-moon-bg: linear-gradient(to top, #d00 0%, #fc8b53 50%, #fdd55a 100%);
  --color-moon-bg-size: 200% 200%;
  --color-peach-gradient: linear-gradient(40deg, #ffd86f, #fc6262);
  --color-purple-gradient: linear-gradient(40deg, #ff6ec4, #7873f5);
  --color-brand: var(--yellow1);
  --brand-text-shadow: -5px 5px 10px #ab870a;
  --bird-fly-speed: 90s;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 767px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  --fb: #3b5998;
  --reddit: #fc471e;
  --h-news: #f60;
  --twitter: #00aced;
  --google: #dd4b39;
  --linkedin: #007bb5;
  --snapchat: #fffc00;
  --skype: #00aff0;
  --wechat: #1aad28;
  --envelope: #2368c0;
  --github: #292626;
  --slack: var(--white);
  --telegram: #039be5;

  /* --reddit: #ff5700; */
  /* #45160814; */
  --stackoverflow: rgb(250 250 251);
  --comment-margin-left: -4em;
  --comment-margin-right: -4em;
  --satellite-color: var(--orange1);
  --satellite-size: 0.5rem;
}

:root [data-theme="dark"] {
  --satellite-color: var(--yellow3);
  --color-moon-bg: lightgoldenrodyellow;
  --color-card-bg: radial-gradient(circle farthest-corner at right bottom, #2c20a6 0, #c642db 100%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --satellite-color: var(--yellow3);
    --color-moon-bg: lightgoldenrodyellow;
    --color-card-bg: radial-gradient(circle farthest-corner at right bottom, #2c20a6 0, #c642db 100%);
  }
}

/* :root [data-theme="gray"] {
} */
/* Resposible varaibles */
/* mobile */
@media only screen and (max-width: 767px),
  only screen and (device-width: 767px) {
  :root {
    --bird-fly-speed: 25s;

    /* --color-global-bg-deg: 45deg; */
    --comment-margin-left: 0;
    --comment-margin-right: 0;
    --satellite-size: 0.3rem;
  }
}

/* Detecting Hover-Capable Devices */
/* @media (hover:hover) and (pointer:fine) {
} */

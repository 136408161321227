.btn,
button,
a {
  text-decoration: none;
}

.btn,
button,
label,
a {
  cursor: pointer;
}

.btn,
button.btn,
a.btn {
  background-image: linear-gradient(to bottom, var(--bg-white), var(--bg-light));
  border-radius: 8px;
  color: var(--green3);
  letter-spacing: 1px;
  padding: 10px 20px;
  border: 0;
  box-shadow: 0 0 10px var(--subtle);
  font: inherit;
  text-decoration: none;
  will-change: transform;
  transition: transform 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

.btn.btn-primary,
button.btn.btn-primary,
a.btn.btn-primary {
  background: var(--color-purple-gradient);
  color: var(--text-color);
}

.btn:hover,
button.btn:hover,
a.btn:hover {
  transform: translateY(-1px);
}

a.link:active {
  outline: var(--text-highlight) auto 1px;
}

.notify-badge {
  position: absolute;
  right: 0.5rem;
  top: 0;
  background: var(--text-highlight);
  text-align: center;
  border-radius: 30px;
  color: white;
  padding: 1px 3px;
  font-size: 8px;
  min-width: 1.2rem;
}

/* common */
details {
  margin: 1.3rem 0;
}

details summary {
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

details > summary {
  list-style: none;
}

/* details>summary::marker, */
details > summary::-webkit-details-marker {
  display: none;
}

details > summary:focus {
  outline: none;
}

details > summary + .content {
  margin: 0;
  padding: 0.5rem 0.5rem 1rem;
}

/* common end */
details > summary.border-bottom {
  padding: 4px 1rem;
  border: none;
  box-shadow: 1px 1px 0 var(--gray6);
  box-shadow: 0 1px 0 var(--gray6);
  font-size: calc(1em + 1vw);
  border-radius: 10px;
}

/* custom-marker */
details > summary.custom-marker {
  position: relative;
  padding-left: 2.5rem !important;

  /* list-style-type: none */
}

details > summary.custom-marker::before,
details > summary.custom-marker::after {
  width: 0.5em;
  height: 0.15em;
  border-radius: 2px;
  background-color: var(--text-highlight);
  content: '';
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;
}

details > summary.custom-marker::before {
  transform: translateY(-50%) rotate(90deg);
}

details[open] > summary.custom-marker::before {
  transform: translateY(-50%) rotate(180deg);
}

/* custom-marker end */

/* style for lazyload resources */
.lazyload {
  opacity: 0.6;
  filter: blur(50px);
	transform: scale(0.8);
}

.lazyloaded {
	opacity: 1;
	transform: scale(1);
	transition: all 600ms;
}

img.error {
  display: inline-block;
  transform: scale(1);
  content: '';

  /* color: transparent; */
}

img.error::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #dcd2d2 url("./../assets/images/bases/break.svg") no-repeat center / 50% 50%;

  /* background: transparent url("./../assets/images/bases/break.svg") no-repeat center / 50% 50%; */
}

img.error::after {
  content: ":(" " " attr(alt);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  line-height: 2;
  background-color: var(--subtle);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
